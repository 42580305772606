import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Tag } from 'antd';
import { CheckOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import './subscriptionCards.scss';
import { getRequest } from '../../api/http';
import { UserServiceApi } from '../../api';
import toastr from 'toastr';


const SubscriptionCards = ({ subscriptionPlans, updateSubscriptionPlans, testCCAvenueFlow, userEmail }) => {
    const [isPromoCodeModalVisible, setPromoCodeModalVisible] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [discountedPrice, setDiscountedPrice] = useState(selectedPlan ? selectedPlan?.price : null);
    const [availablePromoCodes, setAvailablePromoCodes] = useState([])
    const [AppliedCoupon, setAppliedCoupon] = useState({})
    const [showCoupons, setShowCoupons] = useState(false)
    const [promoId, setPromoId] = useState(null)

    useEffect(() => {
        fetchActivePromos()
    }, [])
    const handleRemoveCoupon = () => {
        setPromoCode(null)
        setDiscountedPrice(null);
        setAppliedCoupon({})
        // setAppliedCoupon(null);
    };
    const handleOpenPromoCodeModal = (plan) => {
        setSelectedPlan(plan);
        setPromoCodeModalVisible(true);
    };
    const fetchActivePromos = async () => {
        try {
            const response = await getRequest(UserServiceApi.getPromos());
            setAvailablePromoCodes(response);
        } catch (e) {
            toastr.error(e.message);
        }
    };
    const handleClosePromoCodeModal = () => {
        setPromoCode('');
        setDiscountedPrice(null);
        // setAppliedCoupon(false)
        setAppliedCoupon({})
        setSelectedPlan(null);
        setPromoCodeModalVisible(false);
    };
    const handleApplyPromoCode = async (subid, codeId, codeName) => {
        // Here you can apply the promo code logic, for now, let's assume no discount for simplicity
        // You can replace this with your actual logic
        // For demonstration, let's just set a fixed discount
        setPromoId(codeId)
        setPromoCode(codeName)
        setAppliedCoupon({ [codeId]: true })
        try {
            const response = await getRequest(UserServiceApi.getTotalAmount(subid, codeId));
            setDiscountedPrice(response.netAmount)
        } catch (e) {
            toastr.error(e.message);
        }
    };
    const handleCoupons = () => {
        setShowCoupons(!showCoupons)
        // onChange(inputValue);
    };
    const handleBuyNow = (plan, promoId) => {
        console.log(plan, 'buy now')

        const subscriptionDiscountId = promoId !== null ? promoId : (plan.seasonalDiscountId !== null ? plan.seasonalDiscountId : null);
        const discountApplied = subscriptionDiscountId !== null && subscriptionDiscountId !== undefined;
        // updateSubscriptionPlans(plan.id, discountApplied, discountedPrice);
        updateSubscriptionPlans(plan.id, discountApplied, subscriptionDiscountId)
    };
    // console.log("APPLIED COUPON", AppliedCoupon)
    return (
        <>
            <div className='employer-subscription-cards'>
                <Row>
                    {subscriptionPlans.filter(res => { return res?.name !== "Free Plan" && res?.name !== "Unlimited Plan" && res?.name !== "Custom Plan" }).map((plan, i) => {

                        return <Col xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            key={i}
                            className="subscription-card-col"
                        >
                            <div className='subscription-card'>
                                <div className='subscription-card-header'>
                                    <div className='subscription-plan-title'>
                                        <div className='subscription-plan'>{plan?.name}</div>
                                        <p className='plan-border-bottom'></p>
                                    </div>
                                    <div>
                                        <div className='subscription-plan-cost'>
                                            <div className='plan-cost' style={{ marginRight: plan?.seasonalDiscountPrice ? '35px' : '0px' }}>
                                                {plan?.seasonalDiscountPrice ? (
                                                    <>
                                                        <span className='original-price'>{plan?.price}</span>
                                                        <span className='offer-price'>{plan?.seasonalDiscountPrice}</span>
                                                    </>
                                                ) : (
                                                    <span className='offer-price'>{plan?.price}</span>
                                                )}
                                            </div>
                                            <p>Days {plan?.validityPeriod}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <span className='subscription-divider'></span>
                                </div>
                                <div className='subscription-plan-details'>
                                    <div className='subscription-plans'>
                                        <CheckOutlined className="icon-color" />
                                        {plan?.noOfListings} job listing
                                    </div>
                                   {plan?.noOfResumeViews!==null ? <div className='subscription-plans'>
                                        <CheckOutlined className="icon-color" />
                                        {plan?.noOfResumeViews} profile view
                                    </div> :null}
                                    {(plan?.noOfAssessments !== null && plan?.noOfAssessments > 0) ?
                                        <div className='subscription-plans'>
                                            <CheckOutlined className="icon-color" />
                                            Setup {plan?.noOfAssessments} assessment to filter who can apply
                                        </div>
                                        : null}
                                    {plan?.whatsAppCampaign !== 0 ?
                                        <div className='subscription-plans'>
                                            <CheckOutlined className="icon-color" />
                                            WhatsApp campaign - up to {plan?.whatsAppCampaign} relevant candidates
                                        </div>
                                        : null}
                                    {plan?.accountManager ?
                                        <div className='subscription-plans'>
                                            <CheckOutlined className="icon-color" />
                                            Account Manager
                                        </div>
                                        : null}

                                </div>
                                <div className='subscriptionPlanButton'>
                                    <Button onClick={() => handleOpenPromoCodeModal(plan)} type="primary">Buy Now</Button>
                                    {userEmail === "mythri@timbukdo.in" ?
                                        <Button onClick={() => testCCAvenueFlow()} type="primary" style={{ marginLeft: "10px" }}>Test Buy</Button>
                                        : null}
                                </div>


                            </div>
                        </Col>
                    })}
                </Row>
            </div>
            <Modal
                visible={isPromoCodeModalVisible} // Set this to a state to control the visibility
                onCancel={handleClosePromoCodeModal}
                footer={[
                    <>
                        <div className='space-between'>

                            <div className='footerAmount'>To pay :  ₹  {discountedPrice ?
                                discountedPrice :
                                (selectedPlan?.seasonalDiscountPrice ?
                                    selectedPlan.seasonalDiscountPrice :
                                    selectedPlan?.price)}</div>
                            <Button key="checkout" type="primary" onClick={() => handleBuyNow(selectedPlan, promoId)}>Proceed to Checkout</Button>
                        </div>

                    </>

                ]}
            >
                <h2 className='headersub'>Subscription Details</h2>
                <div>
                    <span className='subscription-divider'></span>
                </div>

                <div className='subscriptiondetails'>
                    <div className='subscriptiondetailItem'>
                        <p>Name</p>
                        <p>{selectedPlan ? selectedPlan.name : ''}</p>
                    </div>
                    <div className='subscriptiondetailItem'>
                        <p>Price</p>
                        <p>{selectedPlan ? selectedPlan.price : ''}</p>
                    </div>
                </div>

                <div className='apply-coupon' onClick={handleCoupons}>
                    Apply Coupon
                    {showCoupons ? <UpOutlined className="arrow-style" /> : <RightOutlined className="arrow-style" />}
                </div>
                {showCoupons &&
                    <div className='couponsContainer'>
                        {availablePromoCodes.map(code => (
                            <div className="promo-container" style={{ opacity: code.valid ? 1 : 0.5 }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <h3>{code.promoCode}</h3>
                                        <p>{code.description}</p>
                                    </div>

                                    {AppliedCoupon[code.id] ? (
                                        <div className='apply-btn'>APPLIED</div>
                                    ) : code.valid ? (
                                        <div className="apply-btn" onClick={() => handleApplyPromoCode(selectedPlan.id, code.id, code.promoCode)}>APPLY</div>
                                    ) : (
                                        <div className='apply-btn' style={{ cursor: 'not-allowed' }}>APPLY</div>
                                    )}

                                </div>
                            </div>
                        ))}
                    </div>

                }

                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                        placeholder="Enter Promo Code"
                        value={promoCode}
                        onChange={handleChange}
                        style={{ marginRight: '10px' }}
                    />
                    <Button type="primary" onClick={handleApplyPromoCode}>Apply</Button>
                </div> */}
                {promoCode ?
                    <div className="applied-coupon space-between" >
                        <div>Coupon Applied :</div>
                        <Tag color="green" closable onClose={handleRemoveCoupon} style={{ marginRight: '0px' }}>{promoCode}</Tag>
                    </div> : null}

                <div className='space-between'>
                    <div >Item Total :</div>
                    {discountedPrice ?
                        (<div className='showAmount'>
                            <div className='gradedAmount'> {selectedPlan?.price}</div>
                            <div className='footerAmount'>₹{discountedPrice}</div>
                        </div>) : selectedPlan?.seasonalDiscountPrice ? (
                            <div className='showAmount'>
                                <div className='gradedAmount'> {selectedPlan?.price}</div>
                                <div className='footerAmount'>₹{selectedPlan?.seasonalDiscountPrice}</div>
                            </div>
                        ) :
                            <div className='footerAmount'>₹{selectedPlan?.price}</div>}
                </div>
                {discountedPrice ?
                    (<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {promoCode} discount Applied
                    </div>) : selectedPlan?.seasonalDiscountPrice ? (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            SEASONAL discount Applied
                        </div>
                    ) :
                        null}
            </Modal>
        </>
    )
}

export default SubscriptionCards